import React from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import BasketPDF from "./BasketPDF";
import "./DownloadPDFButton.css";

const DownloadPDFButton = ({ selectedProducts, totalPrice, isDisabled }) => {
  let clientInfo = null;

  try {
    clientInfo = JSON.parse(localStorage.getItem("clientInfo"));
  } catch (error) {
    console.log("Error accessing client information:", error);
  }

  const date = new Date();
  const formattedDate = `${("0" + date.getDate()).slice(-2)}${(
    "0" +
    (date.getMonth() + 1)
  ).slice(-2)}${date.getFullYear()}`;

  // Construct the filename
  const fileName = `Facture_${
    clientInfo?.companyName ?? "Client"
  }_${formattedDate}.pdf`;

  return (
    <div className="container">
      <PDFDownloadLink
        document={
          <BasketPDF
            selectedProducts={selectedProducts}
            totalPrice={totalPrice}
            clientInfo={clientInfo}
          />
        }
        fileName={fileName}
      >
        {({ blob, url, loading, error }) =>
          loading ? (
            "Loading document..."
          ) : (
            <button className="download-button" disabled={isDisabled}>
              Facture Pro Forma
              <FontAwesomeIcon
                style={{ marginLeft: "8px" }}
                icon={faDownload}
              />
            </button>
          )
        }
      </PDFDownloadLink>
    </div>
  );
};

export default DownloadPDFButton;
