// App.js
import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom"; 
import "./App.css";
import ProductList from "./components/Product/ProductList";
import Basket from "./components/Basket/Basket";
import malicieuseProducts from "./data/bulma-listing-mars-2024.json";
import NavBar from "./components/NavBar/NavBar";
import Footer from "./components/Footer/Footer";
import PasswordPage from "./components/Login/PasswordPage";
import { useAuth } from "./hooks/useAuth";
import { useProducts } from "./hooks/useProducts";
import { useCart } from "./hooks/useCart";

const App = () => {
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const { selectedProducts, handleSelectProduct, handleDeleteProduct } =
    useProducts();
  const { totalPrice, keyCounter, incrementKeyCounter } =
    useCart(selectedProducts);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return (
    <Routes>
      <Route
        path="/login"
        element={<PasswordPage setIsLoggedIn={setIsLoggedIn} />}
      />
      <Route
        path="/*"
        element={
          isLoggedIn ? (
            <div className="app-container">
              <NavBar products={malicieuseProducts} />
              <div className="app-body-container">
                <div className="catalog">
                  <ProductList
                    products={malicieuseProducts}
                    onSelectProduct={handleSelectProduct}
                    keyCounter={keyCounter}
                  />
                </div>
              </div>
              <Basket
                selectedProducts={selectedProducts}
                totalPrice={totalPrice}
                handleDeleteProduct={handleDeleteProduct}
                incrementKeyCounter={incrementKeyCounter}
              />
              <Footer />
            </div>
          ) : null
        }
      />
    </Routes>
  );
};

export default App;
