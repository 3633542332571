// import React, { useState, useEffect } from "react";
// import "./ProfileModal.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUser } from "@fortawesome/free-solid-svg-icons";
// import { countries } from "../../data/countries";

// const ProfileModal = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [clientInfo, setClientInfo] = useState({
//     companyName: "",
//     siretNumber: "",
//     buyerName: "",
//     telephone: "",
//     email: "",
//     address: "",
//     city: "",
//     postcode: "",
//     country: "",
//     shippingAddress: "",
//     vatSubject: false,
//   });

//   useEffect(() => {
//     const storedInfo = JSON.parse(localStorage.getItem("clientInfo"));
//     if (storedInfo) {
//       setClientInfo(storedInfo);
//     }
//   }, []);

//   const toggleModal = () => {
//     setShowModal(!showModal);
//   };

//   const handleInputChange = (e) => {
//     const value =
//       e.target.type === "checkbox" ? e.target.checked : e.target.value;
//     setClientInfo({
//       ...clientInfo,
//       [e.target.name]: value,
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
//     toggleModal();
//     window.location.reload();
//   };

//   const labelNames = {
//     companyName: "Société",
//     siretNumber: "Numéro de siret",
//     buyerName: "Nom de l'acheteur(se)",
//     telephone: "Téléphone",
//     email: "Email",
//     address: "Adresse",
//     city: "Ville",
//     postcode: "Code postal",
//     country: "Pays",
//     shippingAddress: "Adresse de livraison si différent",
//     vatSubject: "Je suis assujeti à la TVA française",
//   };

//   return (
//     <div>
//       <button onClick={toggleModal} className="profile-modal-button">
//         <FontAwesomeIcon icon={faUser} /> <span>Mon Profil</span>
//       </button>
//       {showModal && (
//         <div className={`profile-modal ${showModal ? "show" : ""}`}>
//           <div className="profile-modal-content">
//             <span className="profile-close-button" onClick={toggleModal}>
//               &times;
//             </span>
//             <form onSubmit={handleSubmit}>
//               {Object.keys(clientInfo).map((key) => (
//                 <div key={key}>
//                   <label>{labelNames[key]}</label>
//                   {key !== "vatSubject" && key !== "country" ? (
//                     <input
//                       type="text"
//                       name={key}
//                       value={clientInfo[key]}
//                       onChange={handleInputChange}
//                       placeholder={labelNames[key]}
//                     />
//                   ) : key === "country" ? (
//                     <select
//                       name={key}
//                       value={clientInfo[key]}
//                       onChange={handleInputChange}
//                       required
//                     >
//                       <option value="">Selectionner un pays *</option>
//                       {countries.map((countryName, index) => (
//                         <option key={index} value={countryName}>
//                           {countryName}
//                         </option>
//                       ))}
//                     </select>
//                   ) : (
//                     <input
//                       type="checkbox"
//                       name={key}
//                       checked={clientInfo[key]}
//                       onChange={handleInputChange}
//                     />
//                   )}
//                 </div>
//               ))}
//               <button type="submit">Enregistrer</button>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProfileModal;

import React, { useState, useEffect } from "react";
import "./ProfileModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { countries } from "../../data/countries";

const ProfileModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [clientInfo, setClientInfo] = useState({
    companyName: "",
    siretNumber: "",
    buyerName: "",
    telephone: "",
    email: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    shippingAddress: "",
    vatSubject: false,
  });

  useEffect(() => {
    const storedInfo = JSON.parse(localStorage.getItem("clientInfo"));
    if (storedInfo) {
      setClientInfo(storedInfo);
    }
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleInputChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setClientInfo({
      ...clientInfo,
      [e.target.name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
    toggleModal();
    window.location.reload();
  };

  const labelNames = {
    companyName: "Société",
    siretNumber: "Numéro de siret",
    buyerName: "Nom de l'acheteur(se)",
    telephone: "Téléphone",
    email: "Email",
    address: "Adresse",
    city: "Ville",
    postcode: "Code postal",
    country: "Pays",
    shippingAddress: "Adresse de livraison si différent",
    vatSubject: "Je suis assujeti à la TVA française",
  };

  return (
    <div>
      <button onClick={toggleModal} className="profile-modal-button">
        <FontAwesomeIcon icon={faUser} /> <span>Mon Profil</span>
      </button>
      {showModal && (
        <div className={`profile-modal ${showModal ? "show" : ""}`}>
          <div className="profile-modal-content">
            <span className="profile-close-button" onClick={toggleModal}>
              &times;
            </span>
            <form onSubmit={handleSubmit}>
              {Object.keys(clientInfo).map((key) => (
                <div key={key}>
                  <label>{labelNames[key]}</label>
                  {key !== "vatSubject" && key !== "country" ? (
                    <input
                      type="text"
                      name={key}
                      value={clientInfo[key]}
                      onChange={handleInputChange}
                      placeholder={labelNames[key]}
                    />
                  ) : key === "country" ? (
                    <select
                      name={key}
                      value={clientInfo[key]}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Selectionner un pays *</option>
                      {countries.map((countryName, index) => (
                        <option key={index} value={countryName}>
                          {countryName}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <p>{clientInfo[key] ? "Oui" : "Non"}</p> // Display VAT status
                  )}
                </div>
              ))}
              <button type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileModal;
