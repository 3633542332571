import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <p className="footer-company-name">malicieusediy©2023</p>
      </div>

      <div className="footer-center">
        <div>
          <i className="fa fa-envelope"></i>
          <p>
            <a href="mailto:support@company.com">contact@malicieuse.fr</a>
          </p>
        </div>
      </div>

      <div className="footer-right">
        <div className="footer-icons">
          <a
            href="https://www.instagram.com/malicieuse.diy/?hl=fr"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://www.facebook.com/Malicieuse.fr/?locale=fr_FR"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
