export const countries = [
  "France",
  "Albanie",
  "Allemagne",
  "Andorre",
  "Autriche",
  "Belgique",
  "Bulgarie",
  "Chypre",
  "Croatie",
  "Danemark",
  "Espagne",
  "Estonie",
  "Finlande",
  "Grèce",
  "Hongrie",
  "Irlande",
  "Islande",
  "Italie",
  "Lettonie",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Malte",
  "Monaco",
  "Monténégro",
  "Norvège",
  "Pays-Bas",
  "Pologne",
  "Portugal",
  "République tchèque",
  "Roumanie",
  "Royaume-Uni",
  "Saint-Marin",
  "Serbie",
  "Slovaquie",
  "Slovénie",
  "Suède",
  "Suisse",
  "Ukraine",
  "Afghanistan",
  "Afrique du Sud",
  "Algérie",
  "Albanie",
  "Allemagne",
  "Andorre",
  "Angola",
  "Anguilla",
  "Antarctique",
  "Antigua-et-Barbuda",
  "Arabie saoudite",
  "Argentine",
  "Arménie",
  "Aruba",
  "Australie",
  "Autriche",
  "Azerbaïdjan",
  "Bahamas",
  "Bahreïn",
  "Bangladesh",
  "Barbade",
  "Belau",
  "Belgique",
  "Belize",
  "Bénin",
  "Bermudes",
  "Bhoutan",
  "Biélorussie",
  "Birmanie",
  "Bolivie",
  "Bonaire, Saint-Eustache et Saba",
  "Bosnie-Herzégovine",
  "Botswana",
  "Brésil",
  "Brunéi Darussalam",
  "Bulgarie",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodge",
  "Cameroun",
  "Canada",
  "Chili",
  "Chine",
  "Chypre",
  "Colombie",
  "Comores",
  "Congo",
  "Corée du Nord",
  "Corée du Sud",
  "Costa Rica",
  "Côte d'Ivoire",
  "Croatie",
  "Cuba",
  "Curaçao",
  "Danemark",
  "Djibouti",
  "Dominique",
  "Égypte",
  "Émirats arabes unis",
  "Équateur",
  "Érythrée",
  "Espagne",
  "Estonie",
  "États-Unis",
  "Éthiopie",
  "Îles Féroé",
  "Fidji",
  "Finlande",
  "Gabon",
  "Gambie",
  "Géorgie",
  "Géorgie du Sud-et-les Îles Sandwich du Sud",
  "Ghana",
  "Gibraltar",
  "Grèce",
  "Grenade",
  "Groenland",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernesey",
  "Guinée",
  "Guinée-Bissau",
  "Guinée équatoriale",
  "Guyana",
  "Guyane française",
  "Haïti",
  "Honduras",
  "Hong Kong",
  "Hongrie",
  "Île Bouvet",
  "Île Christmas",
  "Île de Man",
  "Île Norfolk",
  "Îles Caïmans",
  "Îles Cocos",
  "Îles Cook",
  "Îles Féroé",
  "Îles Heard-et-MacDonald",
  "Îles Malouines",
  "Îles Mariannes du Nord",
  "Îles Marshall",
  "Îles Pitcairn",
  "Îles Salomon",
  "Îles Turques-et-Caïques",
  "Îles Vierges britanniques",
  "Îles Vierges des États-Unis",
  "Inde",
  "Indonésie",
  "Iran",
  "Iraq",
  "Islande",
  "Israël",
  "Italie",
  "Jamaïque",
  "Japon",
  "Jersey",
  "Jordanie",
  "Kazakhstan",
  "Kenya",
  "Kirghizistan",
  "Kiribati",
  "Koweït",
  "La Réunion",
  "Laos",
  "Lesotho",
  "Lettonie",
  "Liban",
  "Liberia",
  "Libye",
  "Liechtenstein",
  "Lituanie",
  "Luxembourg",
  "Macao",
  "Macédoine du Nord",
  "Madagascar",
  "Malaisie",
  "Malawi",
  "Maldives",
  "Mali",
  "Malte",
  "Îles Mariannes du Nord",
  "Maroc",
  "Martinique",
  "Maurice",
  "Mauritanie",
  "Mayotte",
  "Mexique",
  "Micronésie",
  "Moldavie",
  "Monaco",
  "Mongolie",
  "Monténégro",
  "Montserrat",
  "Mozambique",
  "Namibie",
  "Nauru",
  "Népal",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norvège",
  "Nouvelle-Calédonie",
  "Nouvelle-Zélande",
  "Oman",
  "Ouganda",
  "Ouzbékistan",
  "Pakistan",
  "Palaos",
  "Palestine",
  "Panama",
  "Papouasie-Nouvelle-Guinée",
  "Paraguay",
  "Pays-Bas",
  "Pérou",
  "Philippines",
  "Pitcairn",
  "Pologne",
  "Polynésie française",
  "Porto Rico",
  "Portugal",
  "Qatar",
  "République centrafricaine",
  "République démocratique du Congo",
  "République dominicaine",
  "République tchèque",
  "Roumanie",
  "Royaume-Uni",
  "Russie",
  "Rwanda",
  "Sahara occidental",
  "Saint-Barthélemy",
  "Saint-Christophe-et-Niévès",
  "Sainte-Hélène",
  "Sainte-Lucie",
  "Saint-Marin",
  "Saint-Martin",
  "Saint-Pierre-et-Miquelon",
  "Saint-Vincent-et-les-Grenadines",
  "Samoa",
  "Samoa américaines",
  "Sao Tomé-et-Principe",
  "Sénégal",
  "Serbie",
  "Seychelles",
  "Sierra Leone",
  "Singapour",
  "Slovaquie",
  "Slovénie",
  "Somalie",
  "Soudan",
  "Soudan du Sud",
  "Sri Lanka",
  "Suède",
  "Suisse",
  "Suriname",
  "Svalbard et Jan Mayen",
  "Syrie",
  "Tadjikistan",
  "Taïwan",
  "Tanzanie",
  "Tchad",
  "Terres australes et antarctiques françaises",
  "Territoire britannique de l'océan Indien",
  "Territoires palestiniens",
  "Thaïlande",
  "Timor oriental",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinité-et-Tobago",
  "Tunisie",
  "Turkménistan",
  "Turquie",
  "Tuvalu",
  "Ukraine",
  "Uruguay",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Viêt Nam",
  "Wallis-et-Futuna",
  "Yémen",
  "Zambie",
  "Zimbabwe",
  "Åland",
];
