export const categorizeProducts = (products) => {
  const categories = {};

  products.forEach((product) => {
    const category = product.name.split(" ")[0];
    if (categories[category]) {
      categories[category].push(product);
    } else {
      categories[category] = [product];
    }
  });

  return categories;
};
