import React, { useState } from "react";
import ProductCard from "./ProductCard";
import { categorizeProducts } from "../../utils/productUtils";
import "./ProductList.css";

const ProductList = ({ products, onSelectProduct, keyCounter }) => {
  const orderedCategories = ["Patch", "Pin's", "Broche", "Porte-clé"]; // Add all categories in desired order

  const [showOnlyNew, setShowOnlyNew] = useState(false);
  const [showAll, setShowAll] = useState(true);

  const handleShowOnlyNew = () => {
    setShowOnlyNew(true);
    setShowAll(false);
  };

  const handleShowAll = () => {
    setShowOnlyNew(false);
    setShowAll(true);
  };

  const filteredProducts = showOnlyNew
    ? products.filter((product) => product.nouveau)
    : products;

  const categorizedFilteredProducts = categorizeProducts(filteredProducts);

  return (
    <div className="product-list-container">
      <div className="filter-button-container">
        <button
          onClick={handleShowOnlyNew}
          className={`filter-button ${showOnlyNew ? "active" : ""}`}
        >
          NOUVEAU !
        </button>
        -
        <button
          onClick={handleShowAll}
          className={`filter-button ${showAll ? "active" : ""}`}
        >
          TOUT
        </button>
      </div>
      {orderedCategories.map((category) => {
        return (
          <div key={category}>
            <h2 className="category-title" id={category}>
              {category}
            </h2>
            {categorizedFilteredProducts[category]?.map((product) => {
              keyCounter++;
              return (
                <ProductCard
                  key={product.name + keyCounter}
                  product={product}
                  onSelectProduct={onSelectProduct}
                />
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ProductList;
