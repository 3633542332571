import React, { useEffect, useState } from "react";
import "./NavBar.css";
import { categorizeProducts } from "../../utils/productUtils";
import ProfileModal from "../MyProfil/ProfileModal";

const NavBar = ({ products }) => {
  const [companyName, setCompanyName] = useState("");
  const categories = categorizeProducts(products);
  const categoryNames = Object.keys(categories);

  useEffect(() => {
    // This function runs whenever localStorage changes
    const handleStorageChange = () => {
      const clientInfo = JSON.parse(localStorage.getItem("clientInfo"));

      if (clientInfo) {
        setCompanyName(clientInfo.companyName);
      }
    };

    // Attach the event listener to the window
    window.addEventListener("storage", handleStorageChange);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-content">
          <h1 className="navbar-title">MALICIEUSE DIY</h1>
          <div className="navbar-items">
            {categoryNames.map((category) => (
              <a href={`#${category}`} className="navbar-item" key={category}>
                {category}
              </a>
            ))}
          </div>
          <div className="navbar-item company-name">
            <ProfileModal />
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
