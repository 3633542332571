// hooks/useCart.js
import { useEffect, useState } from "react";

export const useCart = (selectedProducts) => {
  const [keyCounter, setKeyCounter] = useState(0);

  const totalPrice = Object.values(selectedProducts).reduce(
    (acc, product) => acc + product.proPrice * product.quantity,
    0
  );

  useEffect(() => {
    localStorage.setItem("totalPrice", String(totalPrice));
  }, [totalPrice]);

  const incrementKeyCounter = () => {
    setKeyCounter((prevCounter) => prevCounter + 1);
  };

  return { totalPrice, keyCounter, incrementKeyCounter };
};
