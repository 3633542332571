import React, { useState } from "react";
import "./Basket.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import DownloadPDFButton from "../PDF/DownloadPDFButton";

const Basket = ({
  selectedProducts,
  totalPrice,
  handleDeleteProduct,
  incrementKeyCounter,
}) => {
  const [isBasketVisible, setBasketVisibility] = useState(true);

  const handleBasketToggle = () => {
    setBasketVisibility(!isBasketVisible);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faShoppingBasket}
        className="basket-toggle-icon"
        onClick={handleBasketToggle}
        color={isBasketVisible ? "grey" : "#f26da7"}
      />
      {isBasketVisible && (
        <div className="basket-container">
          <h2 className="title">
            <FontAwesomeIcon icon={faShoppingBasket} color="#f26da7" />
            Mon panier
          </h2>
          {Object.values(selectedProducts).length === 0 ? (
            <p>Pas de produits séléctionné</p>
          ) : (
            <>
              <ul className="list-element">
                {Object.values(selectedProducts).map((product) => (
                  <li key={product.name} className="product-item">
                    <button
                      className="delete-button"
                      onClick={() => {
                        handleDeleteProduct(product);
                        incrementKeyCounter();
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                    <div className="product-info">
                      <span className="product-name">
                        {product.name.length > 45
                          ? product.name.substring(0, 20) + "..."
                          : product.name}
                      </span>
                      <span className="product-quantity-price">
                        {product.quantity} x {product.proPrice}€
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
              {totalPrice < 190 && (
                <p className="order-minimum">
                  Minimum de commande de 190 euros
                </p>
              )}
              <h3 className="total-price">Total : {totalPrice.toFixed(2)} €</h3>
            </>
          )}
          <DownloadPDFButton
            selectedProducts={selectedProducts}
            totalPrice={totalPrice}
            isDisabled={totalPrice < 190}
          />
        </div>
      )}
    </>
  );
};

export default Basket;
