import React from "react";
import "./ImageModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ImageModal = ({ imageUrl, onClose }) => {
  return (
    <div className="modal-background" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} size="3x" />
        </button>
        <img src={imageUrl} alt="" />
      </div>
    </div>
  );
};

export default ImageModal;
