import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import ImageModal from "./ImageModal";
import "./ProductCard.css";

const ProductCard = ({ product, onSelectProduct }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const minQuantity =
    product.minQuantity && !isNaN(product.minQuantity)
      ? product.minQuantity
      : 5;

  const [quantity, setQuantity] = useState(
    localStorage.getItem(product.name)
      ? Number(localStorage.getItem(product.name))
      : 0
  );

  const handleQuantityChange = (delta) => {
    let newQuantity = quantity + delta;

    if (delta > 0 && quantity === 0) {
      // when clicking "+" from 0, jump directly to minQuantity
      newQuantity = minQuantity;
    } else if (delta < 0 && newQuantity < minQuantity) {
      // when clicking "-" and newQuantity is less than minQuantity, jump directly to 0
      newQuantity = 0;
    }

    if (newQuantity === 0) {
      // if new quantity is 0, remove from local storage
      localStorage.removeItem(product.name);
    } else {
      // otherwise, save new quantity to local storage
      localStorage.setItem(product.name, newQuantity);
    }

    setQuantity(newQuantity);
    onSelectProduct(product, newQuantity);
  };

  const imageUrlBase = "https://static.wixstatic.com/media/";
  const imageUrlOptions =
    "/v1/fill/w_916,h_1120,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/";
  const fullImageUrl = `${imageUrlBase}${
    product.productImageUrl.split(";")[0]
  }${imageUrlOptions}${product.productImageUrl.split(";")[0]}`;

  const cardStyle =
    quantity >= minQuantity
      ? { borderColor: "#f26da7", boxShadow: "0 2px 5px #f26da7" }
      : {};

  const isNewProduct = product.nouveau;
  const isBestSeller = product.bestSeller;

  return (
    <div className="card" style={cardStyle}>
      <div className="image-section">
        <img src={fullImageUrl} alt={product.name} />
        <div className="image-overlay" onClick={() => setIsModalVisible(true)}>
          <FontAwesomeIcon icon={faEye} />
        </div>
      </div>
      <div className="info-section">
        <h2 className="name">{product.name}</h2>
        <p className="price">Prix public: {product.publicPrice}€</p>
        <p className="price">Prix pro: {product.proPrice}€</p>
        <div className="chips-container">
          {product.size ? <div className="chip">{product.size}</div> : <></>}
          {isNewProduct && <div className="new-badge">Nouveau !</div>}
          {isBestSeller && (
            <div className="best-seller-badge">BEST SELLER!</div>
          )}
        </div>
      </div>
      <div className="quantity-section">
        <label>Quantity</label>
        <div>
          <button
            onClick={() => handleQuantityChange(-1)}
            data-testid="decrement-button"
            className="quantity-button"
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <input
            className="quantity-input"
            type="number"
            readOnly
            value={quantity}
          />
          <button
            onClick={() => handleQuantityChange(1)}
            data-testid="increment-button"
            className="quantity-button"
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      {isModalVisible && (
        <ImageModal
          imageUrl={fullImageUrl}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </div>
  );
};

export default ProductCard;
