import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const sessionTimeOut = 60 * 60 * 1000; // One hour session timeout in milliseconds
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const lastLoggedIn = localStorage.getItem("lastLoggedIn");
    const currentTime = new Date().getTime();

    // If user has been logged in and the session hasn't expired
    if (lastLoggedIn && currentTime - lastLoggedIn < sessionTimeOut) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      localStorage.removeItem("lastLoggedIn");
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
      localStorage.setItem("lastLoggedIn", new Date().getTime());
    } else {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]);

  return {
    isLoggedIn,
    setIsLoggedIn,
  };
};
