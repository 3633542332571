import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { countries } from "../../data/countries";
import "./PasswordPage.css";

const PasswordPage = ({ setIsLoggedIn }) => {
  const [password, setPassword] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [siretNumber, setSiretNumber] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [postcode, setPostcode] = useState("");
  const [country, setCountry] = useState("");
  const [shippingAddress, setShippingAddress] = useState("");
  const [vatSubject, setVatSubject] = useState(false);
  const [formError, setFormError] = useState("");
  const navigate = useNavigate();
  const correctPassword = "pro2023";

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === correctPassword) {
      const clientInfo = {
        companyName,
        siretNumber,
        buyerName,
        telephone,
        email,
        address,
        city,
        postcode,
        country,
        shippingAddress,
        vatSubject,
      };
      localStorage.setItem("clientInfo", JSON.stringify(clientInfo));
      setIsLoggedIn(true);
      navigate("/");
    } else {
      setFormError(
        "Mot de passe incorrect. Si vous ne connaissez pas le mot de passe, contactez Malicieuse."
      );
    }
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setVatSubject(e.target.value === "France");
  };

  return (
    <div className="password-page">
      <h1 className="title">Merci de compléter les informations</h1>
      <div className="form-container">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Société *"
            required
          />

          <input
            type="text"
            value={siretNumber}
            onChange={(e) => setSiretNumber(e.target.value)}
            placeholder="Numéro de siret *"
            required
          />

          <div className="form-row">
            <input
              type="text"
              value={buyerName}
              onChange={(e) => setBuyerName(e.target.value)}
              placeholder="Acheteur(se)"
              className="half-width-input"
            />
            <input
              type="tel"
              value={telephone}
              onChange={(e) => setTelephone(e.target.value)}
              placeholder="Téléphone *"
              required
              className="half-width-input"
            />
          </div>

          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email *"
            required
          />

          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Adresse *"
            required
          />
          <div className="form-row">
            <input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              placeholder="Ville *"
              required
              className="half-width-input"
            />
            <input
              type="text"
              value={postcode}
              onChange={(e) => setPostcode(e.target.value)}
              placeholder="Code postal *"
              required
              className="half-width-input"
            />
          </div>
          <input
            type="text"
            value={shippingAddress}
            onChange={(e) => setShippingAddress(e.target.value)}
            placeholder="Adresse de livraison si différent"
          />

          <select
            value={country}
            onChange={handleCountryChange}
            required
            style={{ paddingRight: "30px" }}
          >
            <option value="">Selectionner un pays *</option>
            {countries.map((countryName, index) => (
              <option key={index} value={countryName}>
                {countryName}
              </option>
            ))}
          </select>

          {/* <div className="requirements-checkbox">
            <input
              type="checkbox"
              checked={vatSubject}
              onChange={() => setVatSubject(!vatSubject)}
            />
            Je suis assujeti à la TVA française
          </div> */}

          <div className="requirements-box">
            <h3>Conditions de ventes</h3>
            <ul>
              <li>Minimum de commande : 190 € HT</li>
              <li>5 exemplaires minimum par référence</li>
              <li>
                Frais de port : offerts à partir de 450 € d'achat en France ou
                650 € hors France
              </li>
              <li>Paiement à la commande : virement bancaire ou CB</li>
            </ul>
          </div>
          <div className="separator" />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Entrer le mot de passe *"
            title="Si vous ne connaissez pas le mot de passe, contactez Malicieuse directement à cette adresse contact@malicieuse.fr"
            required
          />
          <button type="submit">VALIDER</button>
        </form>
        {formError && <p className="error-message">{formError}</p>}
      </div>
    </div>
  );
};

export default PasswordPage;
