import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React from "react";

const styles = StyleSheet.create({
  page: { padding: 30, backgroundColor: "#f9f9f9" },
  header: {
    flexDirection: "row",
    justifyContent: "center", // Changes text alignment to center
    alignItems: "center",
    marginBottom: 20,
    backgroundColor: "#F26DA7", // Sets background color to pink
    padding: 20, // Padding to give the header some space
  },
  logo: { width: 60, height: 60, marginBottom: 5 },
  invoice: { fontSize: 10, marginBottom: 5 },
  infoContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  company: { fontSize: 10, marginBottom: 5 },
  clientInfo: { fontSize: 10, marginBottom: 5 },
  address: { fontSize: 10, marginBottom: 5 },
  box: {
    padding: 10,
    borderRadius: 5,
    marginBottom: 10,
    width: "45%",
  },
  title: { fontSize: 10, marginBottom: 10, marginTop: 20 },
  listItem: { marginBottom: 5, flexDirection: "row" },
  listItemText: { fontSize: 10 },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    backgroundColor: "#ffffff",
  },
  tableRowHeader: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    backgroundColor: "#F26DA7",
  },
  tableCol: {
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },
  tableColProductName: {
    width: "40%",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },
  tableColQuantity: {
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },
  // tableColFirst: {
  //   width: "25%",
  //   borderLeftWidth: 1,
  //   borderLeftColor: "#ddd",
  //   borderRightWidth: 1,
  //   borderRightColor: "#ddd",
  // },
  tableColFirstProductName: {
    width: "40%",
    borderLeftWidth: 1,
    borderLeftColor: "#ddd",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },
  tableColLast: {
    width: "20%",
    borderRightWidth: 1,
    borderRightColor: "#ddd",
  },
  tableCell: { margin: 5, fontSize: 10 },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    color: "white",
    textTransform: "uppercase",
  },
  totalInfo: {
    flexDirection: "row",
    padding: "0px 5px",
    justifyContent: "space-between",
    backgroundColor: "#cdcdcd",
    alignItems: "center",
    color: "#333",
    fontWeight: "bold",
    paddingTop: "5px",
    paddingBottom: "5px",
    fontSize: 12,
    marginTop: 10,
    marginBottom: 10,
    borderBottomWidth: 1,
  },
  vatInfo: {
    flexDirection: "row",
    padding: "5px",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#333",
    fontWeight: "bold",
    fontSize: 10,
    marginTop: 10,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    position: "absolute",
    fontSize: 8,
    bottom: 10,
    textAlign: "center",
    color: "#444444",
    width: "100vw", // Updated width
  },
  boldText: {
    // New style for bold text
    fontWeight: "bold",
  },
  title: {
    fontSize: 12, // Adjust to your preference
    fontWeight: 600, // Adjust to your preference
    marginBottom: 10,
    marginTop: 20,
  },
  InvoiceTitle: {
    fontSize: 20, // Adjust to your preference
    fontWeight: 600, // Adjust to your preference
    marginBottom: "5px",
    color: "#fff",
  },
  InvoiceDateDelivery: {
    fontSize: 12, // Adjust to your preference
    fontWeight: 300, // Adjust to your preference
    color: "#dadada",
    textAlign: "center",
  },
});

const BasketPDF = ({ selectedProducts, totalPrice, clientInfo }) => {
  let shippingFee;
  const totalBeforeVAT = totalPrice;

  if (clientInfo.country === "France") {
    shippingFee = totalBeforeVAT < 450 ? 18 : 0;
  } else {
    shippingFee = totalBeforeVAT < 650 ? 22 : 0;
  }

  const vatRate = clientInfo.vatSubject ? 0.2 : 0;
  const vat = (totalBeforeVAT + shippingFee) * vatRate;
  const totalIncludingVAT = totalBeforeVAT + vat + shippingFee;
  const totalHT = totalBeforeVAT + shippingFee;

  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.header}>
          <View>
            <Text style={styles.InvoiceTitle}>
              Facture Pro Forma MAL
              {Math.floor(100000 + Math.random() * 900000)}
            </Text>
            <Text style={styles.InvoiceDateDelivery}>
              Date de Délivrance: {new Date().toLocaleDateString()}
            </Text>
          </View>
        </View>
        <View style={styles.infoContainer}>
          <View style={styles.box}>
            <Image style={styles.logo} src={require("../../assets/logo.png")} />
            <Text style={styles.company}>MALICIEUSE</Text>
            <Text style={styles.invoice}>SIRET: 98215598800016</Text>
            <Text style={styles.address}>1 Impasse du Panorama</Text>
            <Text style={styles.address}>34690 FABREGUES, FRANCE</Text>
          </View>
          <View style={styles.box}>
            <Text style={[styles.clientInfo, styles.boldText]}>
              Societe: {clientInfo.companyName}
            </Text>
            <Text style={styles.clientInfo}>Nom: {clientInfo.buyerName}</Text>
            <Text style={styles.clientInfo}>
              SIRET: {clientInfo.siretNumber}
            </Text>
            <Text style={styles.address}>Adresse: {clientInfo.address}</Text>
            <Text style={styles.address}>Ville: {clientInfo.city}</Text>
            <Text style={styles.address}>
              Code Postal: {clientInfo.postcode}, {clientInfo.country}
            </Text>
            {clientInfo.shippingAddress ? (
              <Text style={styles.address}>
                Adresse de livraison: {clientInfo.shippingAddress}
              </Text>
            ) : (
              <></>
            )}
            <Text style={styles.clientInfo}>Tel: {clientInfo.telephone}</Text>
            <Text style={styles.clientInfo}>Email: {clientInfo.email}</Text>
          </View>
        </View>
        <Text style={styles.title}>Détails des produits</Text>
        {Object.values(selectedProducts).length === 0 ? (
          <Text>Pas de produits sélectionné</Text>
        ) : (
          <>
            <View style={styles.tableRowHeader}>
              <View style={styles.tableColProductName}>
                <Text style={styles.tableCellHeader}>Product</Text>
              </View>
              <View style={styles.tableColQuantity}>
                <Text style={styles.tableCellHeader}>Quantity</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Price</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellHeader}>Total</Text>
              </View>
            </View>
            {Object.values(selectedProducts).map((product) => (
              <View style={styles.tableRow} key={product.name}>
                <View style={styles.tableColFirstProductName}>
                  <Text style={styles.tableCell}>{product.name}</Text>
                </View>
                <View style={styles.tableColQuantity}>
                  <Text style={styles.tableCell}>{product.quantity}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {product.proPrice.toFixed(2)}€
                  </Text>
                </View>
                <View style={styles.tableColLast}>
                  <Text style={styles.tableCell}>
                    {(product.proPrice * product.quantity).toFixed(2)}€
                  </Text>
                </View>
              </View>
            ))}
            <View style={styles.tableRow}>
              <View style={styles.tableColFirstProductName}>
                <Text style={styles.tableCell}>Frais de port HT:</Text>
              </View>
              <View style={styles.tableColQuantity}>
                <Text style={styles.tableCell}>1</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{shippingFee.toFixed(2)} €</Text>
              </View>
              <View style={styles.tableColLast}>
                <Text style={styles.tableCell}>{shippingFee.toFixed(2)} €</Text>
              </View>
            </View>

            <View style={styles.vatInfo}>
              <Text>TOTAL HT : </Text>
              <Text>{totalHT.toFixed(2)} €</Text>
            </View>
            <View style={styles.vatInfo}>
              <Text>TVA :</Text>
              <Text>{vat.toFixed(2)} €</Text>
            </View>
            <View style={styles.totalInfo}>
              <Text>TOTAL TTC : </Text>
              <Text>{totalIncludingVAT.toFixed(2)} € </Text>
            </View>
          </>
        )}

        <View style={styles.footer}>
          <Text>Conditions juridiques</Text>
          <Text>MALICIEUSE - SIREN : 982155988 - TVA FR50982155988</Text>
        </View>
      </Page>
    </Document>
  );
};

export default BasketPDF;
