// hooks/useProducts.js
import { useState, useEffect } from "react";

export const useProducts = () => {
  const persistedSelectedProducts =
    JSON.parse(localStorage.getItem("selectedProducts")) || {};

  const [selectedProducts, setSelectedProducts] = useState(
    persistedSelectedProducts
  );

  useEffect(() => {
    localStorage.setItem("selectedProducts", JSON.stringify(selectedProducts));
  }, [selectedProducts]);

  const handleSelectProduct = (product, quantity) => {
    setSelectedProducts((prevState) => {
      if (quantity === 0) {
        const newState = { ...prevState };
        delete newState[product.name];
        return newState;
      } else {
        return {
          ...prevState,
          [product.name]: { ...product, quantity },
        };
      }
    });
  };

  const handleDeleteProduct = (product) => {
    setSelectedProducts((prevState) => {
      const newState = { ...prevState };
      delete newState[product.name];
      return newState;
    });
    localStorage.removeItem(product.name);
  };

  return {
    selectedProducts,
    handleSelectProduct,
    handleDeleteProduct,
  };
};
